<template>
  <div class="app-container">
    <el-form
      ref="form"
      :inline="true"
      :model="formInline"
      :rules="rules"
      class="demo-form-inline"
    >
      <el-form-item label="输入报告号码" prop="reportno">
        <el-input v-model="formInline.reportno" placeholder="1124" />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit">查询</el-button>
        <el-button type="primary" @click="onSubmit1">查询所有</el-button>
        <el-button type="success" @click="exportToExcel">导出所有</el-button>
      </el-form-item>
    </el-form>

    <el-table
      :data="list"
      size="mini"
      border
      fit

      @cell-dblclick="bccelldblclick"
    >
      <el-table-column align="center" label="ID" width="55" type="index" :index="indexMethod" />
      <el-table-column label="Report_No" width="200" prop="report_no">

        <template slot-scope="scope">
          {{ scope.row.report_no }}
          <i class="el-icon-platform-eleme" @click="openPdf(scope)" />
        </template>
      </el-table-column>
      <el-table-column label="来源编号" width="250" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.report_no_real }}</span>
        </template>
      </el-table-column>

      <el-table-column label="报告状态">
        <template slot-scope="scope">
          <el-switch v-model="scope.row.status_real" :active-value="true" :inactive-value="false" />
        </template>
      </el-table-column>

    </el-table>
     <!-- 分页组件 -->
    <el-pagination
      :current-page="formInline.page_num"
      :page-sizes="[100]"
      :page-size="formInline.page_size"
      layout="total, sizes, prev, pager, next, jumper"
      :total="formInline.total"
      @size-change="handleSizeChange1"
      @current-change="handleCurrentChange1"
    />

    <report-table ref="ReportTable" :show-dialog1.sync="showDialog1" :list="list1" />

    <h2>已上传总表</h2>

    <el-table
      :data="uploaded_list"
      size="mini"
      border
      fit

      @cell-dblclick="bccelldblclick"
    >
      <el-table-column align="center" label="ID" width="75" type="index" :index="indexMethod" />
      <el-table-column align="center" label="ID" width="75" prop="id" />
      <el-table-column label="Report_No" width="140" prop="report_no" />
      <el-table-column label="报告类型" width="100" align="center" prop="cert_type" />
      <el-table-column label="报告链接" width="700" align="center" prop="file_url" show-overflow-tooltip />

      <el-table-column label="返回字符串" prop="res_json" />

    </el-table>
    <el-pagination
      :current-page="formInline1.page"
      :page-sizes="[10, 20, 30, 50]"
      :page-size="formInline1.page_size"
      layout="total, sizes, prev, pager, next, jumper"
      :total="formInline1.total"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
  </div>
</template>

<script>
import { getFile, getNeedUploadList, getReportAllNo, getReportList } from '@/api/user'
import ReportTable from '../report_list/component/report_table.vue'
import XLSX from 'xlsx'
export default {
  components: { ReportTable },
  filters: {
    statusFilter(status) {
      const statusMap = {
        published: 'success',
        draft: 'gray',
        deleted: 'danger'
      }
      return statusMap[status]
    }
  },
  data() {
    return {
      list: null,
      uploaded_list: null,
      list1: null,
      ReportNo: '',
      status: false,
      all: [],
      FileUrl: '',
      FileNo: '',
      url: 'https://report.annrayonline.com',
      listLoading: true,
      formInline: {
        reportno: '11',
        total: 0,
        page_num: 1,
        page_size: 100
      },
      formInline1: {

        total: 0,
        page: 1,
        page_size: 10
      },
      showDialog1: false,
      rules: {
        reportno: [{ required: true, message: '报告号码不能为空', trigger: 'blur' }]
      }

    }
  },
  mounted() {
    this.getReportAllNo()
    this.getUploadList()
  },
  created() {
    // if (this.formInline.reportno !== '1124') {
      this.onSubmit()
    // }
  },
  methods: {

    // 处理分页
    handleSizeChange(val) {
      this.formInline1.page_size = val
      this.getUploadList()
    },
    handleCurrentChange(val) {
      this.formInline1.page = val
      this.getUploadList()
    },
    // 获取上传列表
    
     handleSizeChange1(val) {
      this.formInline.page_size = val
      this.onSubmit()
    },
    handleCurrentChange1(val) {
      this.formInline.page_num = val
      this.onSubmit()
    },
    
    
    formatStatus(row, column, cellValue, index) {
      console.log(cellValue)
      return cellValue === 1
    },

    indexMethod(index) {
      return index + 1
    },
    fetchData() {
      // this.listLoading = true
      getFile(this.formInline).then(response => {
        this.list = response.data
        // this.listLoading = false
      })
    },

    // 处理字符串
    oprateno(str) {
      // 先截取字符串
      const str1 = str.substr(1, 14).trim()
      // 再处理字符串
      if (str1[12] === 'A' || str1[12] === 'B' || str1[12] === 'R') {
        return str1.substr(0, 14)
      } else {
        return str1.substr(0, 12)
      }
    },

    async getReportAllNo() {
      const res = await getReportAllNo()
      this.all = res
    },

    // 查询报告是否已上传
    CheckNo(reportno) {
      // 使用 includes() 方法进行判断
      // if (this.all.some(item => JSON.stringify(item) === JSON.stringify({ reportno: 'A' + this.oprateno(reportno) }))) {
      //   console.log('存在该值')
      // } else {
      //   console.log('不存在该值')
      // }
      const list1 = []
      for (let i = 0; i < this.all.length; i++) {
        list1.push(this.all[i].report_no)
      }
      if (list1.includes('A' + this.oprateno(reportno))) { return true } else {
        return false
      }
    },
    // 得到所有上传的报告
    async getUploadList() {
      const list = await getReportList(this.formInline1)
      this.uploaded_list = list.data
      this.formInline1.total = list.total
      console.log(this.uploaded_list)
    },
    async onSubmit() {
      this.$refs.form.validate(async(isOK) => {
        if (isOK) {
          const list = await getNeedUploadList(this.formInline)

          const result = list.result.items
          this.formInline.total = list.result.total
          console.log(result)
          const item = []
          for (let i = 0; i < result.length; i++) {
            const reportno = this.oprateno(result[i])

            item.push(
              {
                'report_no': reportno,
                'report_no_real': result[i],
                'status_real': this.CheckNo(result[i])
              }
            )
          }
          this.list = item

          this.listLoading = false
        }
      })
    },
    
    async fetchAllNeedUploadList() {
        const allData = [];
        let currentPage = 1;
        const pageSize = 100; // 根据实际情况调整每页大小
        let total = 0;
    
        do {
          this.formInline.page_num = currentPage;
          this.formInline.page_size = pageSize;
    
          try {
            const response = await getNeedUploadList(this.formInline);
            const result = response.result.items;
            total = response.result.total;
    
            allData.push(...result);
            currentPage++;
          } catch (error) {
            console.error('获取数据时出错:', error);
            break; // 出错时停止获取数据
          }
        } while ((currentPage - 1) * pageSize < total);
    console.log(allData)
    return allData;
    
  },

  async onSubmit1() {
    this.$refs.form.validate(async (isOK) => {
      if (isOK) {
        try {
          const allData = await this.fetchAllNeedUploadList();
          const item = allData.map(reportno => ({
            'report_no': this.oprateno(reportno),
            'report_no_real': reportno,
            'status_real': this.CheckNo(reportno)
          }));
          this.list = item;
          console.log(this.list)
          this.listLoading = false;
        } catch (error) {
          console.error('处理数据时出错:', error);
          this.listLoading = false;
        }
      }
    });
  },

    uploadPage() {
      location.reload()
    },

    bccelldblclick(row, column, cell, event) {
      this.$nextTick(async() => {
        const list = await getFile({
          'reportno': row.report_no })

        console.log(list)
        this.list1 = list
        // this.ReportNo = row.report_no
        // this.FileUrl = this.url + row.path.substr(1)
        // this.FileNo = row.org_name.split('_')[0]

        // this.$refs.PddUpload 等同于子组件的this
      })
      this.showDialog1 = true
      // setTimeout(() => {
      //   this.$refs.PddUpload.getList()
      // }, 3000)
    },
    openPdf(scope) {
      window.open(this.url + scope.row.path.substr(1))
    },
    exportToExcel() {
      const worksheet = XLSX.utils.json_to_sheet(this.list);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
      XLSX.writeFile(workbook, 'report_list.xlsx');
    }
    

  }
}
</script>
